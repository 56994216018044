<template>
    <div>
        <el-card shadow="never">
            <template #header>
                <div class="clearfix" style="text-align:left">
                    <span>模板列表</span>
                </div>
            </template>
            <div class="content">
                <div class="content-header">
                    <el-row :gutter="5">
                        <el-col :span="8"   style="text-align:left;">
                            <div class="searchblock">
                                <el-button type="primary" size="small" @click="SelectMerchant" style="margin-right:10px;" v-if="ismerchant != 1">选择商户</el-button>
                                <el-select v-model="status" style="margin-right:10px;width:250px;" size="small" >
                                    <el-option label="全部" value="0"></el-option>
                                    <el-option label="启用" value="1"></el-option>
                                    <el-option label="停用" value="2"></el-option>
                                </el-select>
                                <el-input v-model="keyword" placeholder="模板名称" prefix-icon="el-icon-search" class="hidden-sm-and-down" size="small" clearable></el-input>
                                <el-button type="primary" @click="init" style="margin-left:10px;" class="hidden-sm-and-down" size="small">搜索</el-button>
                                <el-button type="success" @click="add" size="small">添加</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="content-main">
                <el-table :data="tabledata" border style="width: 100%; margin: 1em 0"
                                v-loading="load" 
                                size="small"
                                element-loading-spinner="el-icon-loading" 
                                element-loading-text="加载中..."
                                >
                    <el-table-column label="所属商户" prop="Merchant" >
                        <template #default="scope">
                            <span v-if="scope.row.Merchant">{{scope.row.Merchant}}</span>
                            <span v-else>深圳市闲牛科技有限公司</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="标题" width="200" prop="Title"></el-table-column>
                    <el-table-column label="描述" prop="TempDesc" :show-overflow-tooltip='true'>
                        
                    </el-table-column>
                    <el-table-column label="创建时间" width="200" prop="AddDTime" :formatter="Getdate"></el-table-column>
                    <el-table-column label="状态" width="100">
                        <template #default="scope">
                            <span v-if="scope.row.Status == 0"><span style="color:red">停用</span></span>
                            <span v-if="scope.row.Status == 1">启用</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="400px">
                        <template #default="scope">
                            <el-button type="primary" style="margin-right:20px;" @click="Detail(scope.row.ID)" size="small">详情</el-button>
                            <el-button type="primary" style="margin-right:20px;" @click="MoveUp(scope.$index)" size="small">上移</el-button>
                            <el-button type="primary" style="margin-right:20px;" @click="MoveDown(scope.$index)" size="small">下移</el-button>
                            <el-button v-if="scope.row.Status == 0" type="primary"  @click="Enable(scope.row.ID,scope.row.MerchantID)" style="margin-right:20px;" size="small">启用</el-button>
                            <el-button v-if="scope.row.Status == 1" type="primary"  @click="Disable(scope.row.ID,scope.row.MerchantID)" style="margin-right:20px;" size="small">停用</el-button>
                            <el-button type="primary"  @click="Edit(scope.row.ID)" :disabled="scope.row.Status == 0?false:true" style="margin-right:20px;" size="small">
                                编辑</el-button>
                            <el-button type="primary"   @click="Del(scope.row.ID,scope.row.MerchantID)" :disabled="scope.row.Status == 0?false:true" size="small">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="handleCurrentChange"
                                @size-change="handleSizeChange"
                                :current-page="curpage"
                                :page-size="pagesize"
                                :page-sizes="[10,20,30,50,100]"
                                layout="sizes,total, prev, pager, next, jumper"
                                :total="totalcount"
                                :hide-on-single-page="true"
                                style="text-align:center"></el-pagination>
            </div>
        </el-card>
    </div>
    <el-dialog v-model="editor" title="编辑模板">
        <el-form :model="tempinfo" label-position="right" :rules="rule" ref="tempinfo" class="tempinfo">
            <el-form-item label="所属商户" label-width="100px" prop="MerchantID" v-if="ismerchant != 1">
                <el-button type="primary" size="small" @click="SelectMerchant" style="margin-right:10px;">选择商户</el-button>
            </el-form-item>
            <el-form-item label="模板标题" label-width="100px" prop="Title">
                <el-input v-model="tempinfo.Title"></el-input>
            </el-form-item>
            <el-form-item label="描述"  label-width="100px" prop="TempDesc">
                <el-input type="textarea" rows="4" v-model="tempinfo.TempDesc"></el-input>
            </el-form-item>
            <el-form-item label="上传图片" prop="imgList" label-width="100px">

                <el-upload  :action="upload_url"
                            multiple
                            :file-list="FileList"
                            accept="image/*"
                            :on-success="UploadSuccess" 
                            :on-change="UploadChange" 
                            :on-error="UploadError" 
                            :on-exceed="UploadExceed"
                            :on-remove="RemovePic"
                            :on-preview="Preview"
                            list-type="picture-card"
                            :before-upload="CheckFile"
                            :limit="9"
                    >
                    <i class="el-icon-plus"></i>
                    <template #tip>
                        <div class="el-upload__tip">只能上传 jpg/png/pdf 文件,最多上传9张</div>
                    </template>
                </el-upload>
                <el-dialog v-model="dialogVisible">
                    <img style="width:100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
            <el-button @click="CloseDialog('tempinfo')">取 消</el-button>
            <el-button type="primary" @click="submitform('tempinfo')">确 定</el-button>
            </span>
        </template>
    </el-dialog>
    <el-dialog v-model="detail" title="模板详情">
        <!--<el-image v-for="url in urls" :key="url" :src="url" lazy></el-image>-->
        <div class="templist">
            <el-image class="tempimg" v-for="url in urls" :key="url" :src="url" lazy :preview-src-list="urls"></el-image>
        </div>
    </el-dialog>
    <el-dialog v-model="merchant" title="选择商户" center>
        <div class="merchant-block">
            <el-transfer v-model="selmerchant"
                filterable
                filter-placeholder="请输入商户名称"
                :data='merchantlist'
                :titles='transfertitle'>
            </el-transfer>
            <div class="bottom">
                <el-button type="primary" size="small" @click="ConfirmMerchant">确定</el-button>
                <el-button size="small" @click="Back">取消</el-button>
            </div>
        </div>
    </el-dialog>
</template>
<style scoped>
.searchblock{
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
}
.merchant-block {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.merchant-block .bottom {
    margin-top: 20px;
    display:flex;
    justify-content: center;
    align-items: center;
}
.templist{
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}
.templist .tempimg {
    width: 150px;
    height: auto;
    padding: 20px;
}
.tempinfo:deep() .input-size {
    width: 40em;
}
.tempinfo:deep() .avatar-uploader-icon {
    font-size: 40px;
    color: #8c939d;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
}
.tempinfo:deep() .avatar {
    width: 80px;
    display: block;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
}
</style>
<script>
import Cookies from 'js-cookie'
import qs from 'qs'
import { ElMessage } from 'element-plus'
import constant from "@/constant"
export default {
    data(){
        return {
            selmerchant:[],
            transfertitle:['商户列表','已选商户'],
            merchantlist:[],
            merchant:false,
            dialogImageUrl: '',
            dialogVisible: false,
            urls:[],
            detail:false,
            tabledata:[],
            upload_url:constant.upload_temp_img_url,
            editor:false,
            FileList:[],
            rule:{
                Title:[{required: true, message: '请输入模板标题',trigger: 'blur'}],
            },
            tempinfo:{
                TempDesc:'',
                Title:'',
                imgList:[],
                ID:''
            },
            curpage:1,
            pagesize:10,
            totalcount:0,
            keyword:'',
            status:'0',
            
        }
    },
    created:function(){
        this.ismerchant = Cookies.get("IsMerchant");
        this.mid = Cookies.get("mid");
        this.axios.get(constant.get_temp_url,{
            headers:{
                'Content-Type': 'application/json'
            },
            params:{
                ismerchant:this.ismerchant,
                mid:this.mid,
            }
        }).then((response)=>{
            console.log(response.data);
            this.tabledata = response.data.list;
            this.curpage = response.data.curpage*1;
            this.totalcount = response.data.totalcount*1;
        });

        this.axios.get(constant.allmerchant,{
            headers:{
                "Content-Type":'application/json'
            }
        }).then((response)=>{
            console.log("商户列表");
            console.log(response.data);
            let list = response.data;
            this.merchantlist = [];
            for(let i = 0;i<list.length;i++){
                let obj = new Object();
                obj.label = list[i]['Merchant'];
                obj.key = list[i]['ID'];
                this.merchantlist.push(obj);
            }
            console.log(this.merchantlist);
        });
    },
    methods:{
        init(){
            this.axios.get(constant.get_temp_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    curpage:this.curpage,
                    pagesize:this.pagesize,
                    keyword:this.keyword,
                    status:this.status,
                    ismerchant:this.ismerchant,
                    mid:this.mid,
                    selmerchant:JSON.stringify(this.selmerchant),
                }
            }).then((response)=>{
                console.log(response.data);
                this.tabledata = response.data.list;
                this.curpage = response.data.curpage*1;
                this.totalcount = response.data.totalcount*1;
            });
        },
        ConfirmMerchant(){
            console.log(this.selmerchant);
            this.merchant = false;
        },
        Back(){
            this.merchant = false;
        },
        SelectMerchant(){
            
            this.merchant = true;
        },
        add(){
            this.tempinfo.TempDesc = '';
            this.tempinfo.Title = '';
            this.tempinfo.imgList = [];
            this.FileList = [];
            this.editor = true;
        },
        UploadExceed(file,filelist){
            this.$message.warning(`当前限制选择 9 个图片，本次选择了 ${file.length} 个文件，共选择了 ${file.length + filelist.length} 个文件`);
        },
        UploadSuccess(response,file,filelist){
            console.log(file);
            console.log(filelist);
            console.log(response);
            if(response.error == '0'){
               this.tempinfo.imgList = [];
                for(var i = 0;i<filelist.length;i++){
                    var uid = filelist[i]['uid'];
                    var name = filelist[i]['name'];
                    var url = '';
                    if(filelist[i]['response']){
                        var upload_response = filelist[i]['response'];
                        url = upload_response.url;
                    }else{
                        url = filelist[i]['url'];
                    }
                    var obj = new Object();
                    obj.uid = uid;
                    obj.name = name;
                    obj.url = url;
                    this.tempinfo.imgList.push(obj);
                }
            }
            console.log(this.tempinfo.imgList);
        },
        RemovePic(file,filelist){
            console.log(file);
            console.log(filelist);
            this.tempinfo.imgList = [];
                for(var i = 0;i<filelist.length;i++){
                    var uid = filelist[i]['uid'];
                    var name = filelist[i]['name'];
                    var url = filelist[i]['url'];
                    var obj = new Object();
                    obj.uid = uid;
                    obj.name = name;
                    obj.url = url;
                    this.tempinfo.imgList.push(obj);
                }
        },
        CheckFile(file){
            console.log(file);
            if(file.type != "image/jpg" && file.type !="image/png" && file.type != "image/jpeg"){
                this.$message.warning('当前选择文件中包含不支持上传的类型');
                return false;
            }
        },
        UploadError(err){
            console.log(err);
            this.$message.error(err);
        },
        submitform(formName){
            this.$refs[formName].validate((valid)=>{
                if(valid){
                    console.log(JSON.stringify(this.selmerchant));
                    this.axios.post(constant.save_temp_url,qs.stringify(this.tempinfo),{
                            headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                            params:{
                                mids:JSON.stringify(this.selmerchant),
                                ismerchant:this.ismerchant,
                                mid:this.mid,
                            }
                    }).then((response)=>{
                        console.log(response.data);
                        if(response.data == 'OK'){
                            
                            this.$message.success("操作成功");
                            this.editor = false;
                            this.$router.go(0);
                        }
                    });
                }else{
                    return false;
                }
            });
        },
        Getdate(row, column){
            var cur_date = row[column.property];
            console.log(cur_date);
            return  formatDate(cur_date);
            function formatDate(date){
                var datetime = new Date(date*1000);
                var year = datetime.getFullYear();
                var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
                var day = ("0" + datetime.getDate()).slice(-2);
                return year + "-" + month + "-" + day;
            }
        },
        Enable(id,merchantid){
            this.axios.get(constant.enable_temp_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    id:id,
                    merchantid:merchantid,
                }
            }).then((response)=>{
                console.log(response);
                if(response.data == "OK"){
                    this.$router.go(0);
                }else{
                    ElMessage.error({
                        type:'error',
                        message: '操作失败'
                    });
                }
            });
            return false;
        },
        Disable(id,merchantid){
            this.axios.get(constant.disable_temp_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    id:id,
                    merchantid:merchantid,
                }
            }).then((response)=>{
                console.log(response);
                if(response.data == "OK"){
                    this.$router.go(0);
                }else{
                    ElMessage.error({
                        type:'error',
                        message: '操作失败'
                    });
                }
            });
            return false;
        },
        Edit(id){
            if(!id){
                return false;
            }
            this.axios.get(constant.get_temp_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response.data);
                this.editor = true;
                this.tempinfo = response.data;
                if(this.ismerchant != 1)
                    this.selmerchant = response.data.selmerchant;
                
                this.FileList = response.data.TempImg;
            });
        },
        Detail(id){
            if(!id)
                return false;
            this.axios.get(constant.get_temp_detail,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response.data);
                this.urls = response.data;
                this.detail = true;
            });
        },
        Del(id,merchantid){
            if(!confirm("您确定要删除该模板么？"))
                return false;
            this.axios.get(constant.del_temp_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    id:id,
                    merchantid:merchantid,
                }
            }).then((response)=>{
                console.log(response.data);
                
                if(response.data == 'OK'){
                    this.$router.go(0);
                }else if(response.data == 'USING'){
                    ElMessage.error({
                        type:'error',
                        message: '存在正在使用该模板的商品'
                    });
                    return false;
                }
            });
        },
        Preview(file) {
            console.log(file);
            if(file.response){
                if(file.response.error == 0){
                    this.dialogImageUrl = file.response.url;
                    console.log(this.dialogImageUrl);
                }
            }
            else{
                this.dialogImageUrl = file.url;
            }
            
            this.dialogVisible = true;
        },
        CloseDialog(formName){
            this.editor = false;
            this.$refs[formName].resetFields();
        },
        SwapArray(arr, index1, index2){
            arr[index1] = arr.splice(index2,1,arr[index1])[0];
            let newtable = [];
            for(let i=0;i<arr.length;i++){
                newtable.push(arr[i]);
            }
            this.tabledata = newtable;
        },
        MoveUp(index){
            console.log(index);
            console.log(this.tabledata);
            if(index != 0){
                this.SwapArray(this.tabledata,index,index-1);
                
            }
        },
        MoveDown(index){
            console.log(index);
            console.log(this.tabledata);
            if(index + 1 != this.tabledata.length){
                this.SwapArray(this.tabledata,index,index+1);
            }
        },
        MoveRowUp(index){
            var that = this;
            if(index > 0){
                let update = that.tabledata[index - 1];
                that.tabledata.splice(index - 1,1);
                that.tabledata.splice(index,0,update);
            }else{
                that.$message.error("已经是最顶层了");
            }
        },
        MoveRowDown(index){
            var that = this;
            if((index + 1) === that.tabledata.length){
                that.$message.error("已经是最底层了");
            }else{
                let downdate = that.tabledata[index + 1];
                that.tabledata.splice(index + 1,1);
                that.tabledata.splice(index,0,downdate);
            }
        },
        handleSizeChange(val){
            console.log(val);
            this.pagesize = val;
            this.init();
        },
        handleCurrentChange(val){
            console.log(val);
            this.curpage = val;
            this.init();
        },
    }
}
</script>